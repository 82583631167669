import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/yellow.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  render() {
    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="jennylee" style={{ width: '70px' }} />
            </Link>
            {/* Hamburger menu */}
          </div>
          <div
            id="navMenu"
            className="navbar-menu is-active"
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/blog">
                tech blog
              </Link>
              <Link className="navbar-item" to="/contact">
                contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
