import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className="container">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        posts
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        contact
                      </Link>
                    </li>
                    <li>
                      <p className="copy"> © 2024 jennylee </p>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
